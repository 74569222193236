import { NavLink } from 'react-router-dom';

export default function CartFloating() {
    const floatingStyle = {
        position: 'fixed',
        width: '50px',
        height: '50px',
        bottom: '80px',
        right: '20px',
        backgroundColor: '#4338ca',
        color: '#fff',
        borderRadius: '50px',
        textAlign: 'center',
        fontSize: '30px',
        boxShadow: '2px 2px 3px #999',
        zIndex: '100',
    };

    return (
        <>
            <link
                rel='stylesheet'
                href='https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css'
            />
            <NavLink to={'/checkout'} style={floatingStyle}>
                <i
                    className='fa fa-cart-plus'
                    style={{ marginTop: '11px' }}
                ></i>
            </NavLink>
        </>
    );
}
