import './../../App.css';
import React, { useState } from 'react';
import { LockClosedIcon, UserPlusIcon } from '@heroicons/react/20/solid';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { showError, showSuccess } from '../../Components/Message';
import InputField from '../../Components/Forms/InputField';
import Button from '../../Components/Forms/Button';
import AuthService from '../../services/AuthService';

export default function Login() {
    const navigate = useNavigate();
    const defaultLoginValue = {
        email: '',
        password: '',
    };

    const [loginForm, setLoginForm] = useState(defaultLoginValue);
    const [error, setError] = useState(defaultLoginValue);
    const [loginLoading, setLoginLoading] = useState(false);

    //Handler
    const loginHandler = async (e) => {
        e.preventDefault();
        setLoginLoading(true);

        //request api
        try {
            const { data } = await AuthService.login(loginForm);
            if (data.success) {
                //store token to
                const auth = {
                    isAuth: true,
                    data: data,
                    user: data.data,
                    token: data.token,
                };
                await localStorage.setItem('auth', JSON.stringify(auth));

                showSuccess(data.message);
                navigate('/');
            } else {
                let response = data.response.data;
                showError(response.message);
            }
        } catch ({ response }) {
            try {
                if (response.data.error) {
                    setError(response.data.error);
                } else if (response.data.message) {
                    showError(response.data.message);
                } else {
                    showError(response);
                }
            } catch (error) {
                console.error(error);
                showError('Error sistem, hubungi admin WA : +6283818213645');
            }
        }
        setLoginLoading(false);
    };

    return (
        <>
            <div className='flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8'>
                <div className='w-full max-w-md space-y-8'>
                    <div>
                        <img
                            className='mx-auto h-24 w-auto'
                            src='/logo/Logo Non BG Purple.png'
                            alt='Your Company'
                        />
                        <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
                            Sign in to your account
                        </h2>
                        <p className='mt-2 text-center text-sm text-gray-600'>
                            Or{' '}
                            <NavLink
                                to='/'
                                className='font-medium text-indigo-600 hover:text-indigo-500'
                            >
                                Back to dashboard
                            </NavLink>
                        </p>
                    </div>
                    <form
                        className='mt-8 space-y-6'
                        action='#'
                        onSubmit={loginHandler}
                    >
                        <input
                            type='hidden'
                            name='remember'
                            defaultValue='true'
                        />
                        <div className='-space-y-px rounded-md shadow-sm'>
                            <div>
                                <InputField
                                    id='email-address'
                                    name='email'
                                    type='email'
                                    label='Email Address'
                                    autoComplete='email'
                                    required
                                    className='relative pl-2 block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    placeholder='Email address'
                                    error={error.email}
                                    onChange={(e) =>
                                        setLoginForm({
                                            ...loginForm,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className='pt-2'>
                                <InputField
                                    id='password'
                                    name='password'
                                    type='password'
                                    label='Password'
                                    autoComplete='current-password'
                                    required
                                    className='relative pl-2 pr-2 block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    placeholder='Password'
                                    error={error.password}
                                    onChange={(e) =>
                                        setLoginForm({
                                            ...loginForm,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>

                        <div className='flex items-center justify-between'>
                            <div className='flex items-center'>
                                <input
                                    id='remember-me'
                                    name='remember-me'
                                    type='checkbox'
                                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                />
                                <label
                                    htmlFor='remember-me'
                                    className='ml-2 block text-sm text-gray-900'
                                >
                                    Remember me
                                </label>
                            </div>

                            <div className='text-sm'>
                                <a
                                    href={`https://api.whatsapp.com/send?phone=${
                                        process.env.REACT_APP_PHONE_CODE +
                                        process.env.REACT_APP_PHONE_NUMBER
                                    }&text=Halo%21%20Saya%20butuh%20bantuan.`}
                                    className='font-medium text-indigo-600 hover:text-indigo-500'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    Forgot your password?
                                </a>
                            </div>
                        </div>

                        <div>
                            <Button
                                type='submit'
                                data-loading={loginLoading}
                                icon={
                                    <LockClosedIcon
                                        className='h-5 w-5 text-white-500 group-hover:text-white-400'
                                        aria-hidden='true'
                                    />
                                }
                                label={'Sign In'}
                            />
                            <Link to='/register'>
                                <Button
                                    className='my-2'
                                    icon={
                                        <UserPlusIcon
                                            className='h-5 w-5 text-white-500 group-hover:text-white-400'
                                            aria-hidden='true'
                                        />
                                    }
                                    label={'Register'}
                                />
                            </Link>
                        </div>
                    </form>
                    <p className='mt-2 text-center text-sm text-gray-600'>
                        Application Version : {process.env.REACT_APP_VERSION}
                    </p>
                </div>
            </div>
        </>
    );
}
