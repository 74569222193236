import React from 'react';

const PrivacyPolicy = () => {
    return (
        <>
            <h1 className='text-3xl font-semibold mb-6'>Privacy Policy</h1>

            <p className='mb-4'>
                <strong>Last Updated:</strong> October 1, 2023
            </p>

            <p>
                Welcome to JURAGANAKUN! This Privacy Policy explains how we
                collect, use, and protect the information you provide when using
                our services. We highly value your privacy and are committed to
                safeguarding your personal data.
            </p>

            <h2 className='text-xl font-semibold mt-6 mb-2'>
                1. Information We Collect
            </h2>
            <ul className='list-disc list-inside ml-4'>
                <li>
                    <strong>Personal information:</strong> We may collect
                    personal information, such as your name, email address,
                    phone number, and other contact information you provide when
                    registering or using our services.
                </li>
                <li>
                    <strong>Usage information:</strong> This includes details
                    about how you use our services, your interactions with us,
                    and other log data.
                </li>
                <li>
                    <strong>Information provided to us:</strong> Information you
                    provide when communicating with us via email, mail, or other
                    communication channels.
                </li>
            </ul>

            <h2 className='text-xl font-semibold mt-6 mb-2'>
                2. How We Use Your Information
            </h2>
            <p>We use the information we collect to:</p>
            <ul className='list-disc list-inside ml-4'>
                <li>Provide our services to you and fulfill your requests.</li>
                <li>
                    Manage your account and send you requested information,
                    including updates and notifications about our services.
                </li>
                <li>Analyze your use of our services and improve them.</li>
                <li>
                    Contact you about products, services, or special promotions
                    that we believe may interest you (if you have granted
                    permission to receive such communications).
                </li>
            </ul>

            <h2 className='text-xl font-semibold mt-6 mb-2'>
                3. How We Protect Your Information
            </h2>
            <p>
                We take necessary steps to protect your personal information
                from unauthorized access, disclosure, alteration, or deletion.
                We implement appropriate technical and organizational security
                measures to safeguard your data.
            </p>

            <h2 className='text-xl font-semibold mt-6 mb-2'>
                4. Sharing Your Information
            </h2>
            <p>
                We will not sell, rent, or exchange your personal information
                with third parties without your consent, except as required by
                law or in the course of providing services to you. We may share
                information with third-party service providers who assist us in
                delivering our services.
            </p>

            <h2 className='text-xl font-semibold mt-6 mb-2'>
                5. Controlling Your Information
            </h2>
            <p>
                You have the right to access, update, or delete the personal
                information we hold about you. You can also choose not to
                receive marketing communications from us by following the
                instructions in the emails we send or by contacting us.
            </p>

            <h2 className='text-xl font-semibold mt-6 mb-2'>
                6. Changes to the Privacy Policy
            </h2>
            <p>
                We reserve the right to modify this Privacy Policy and will
                notify you of such changes. The changes will become effective
                immediately upon notification.
            </p>

            <h2 className='text-xl font-semibold mt-6 mb-2'>7. Contact Us</h2>
            <p>
                If you have questions or need to contact us regarding these
                Terms and Conditions, please reach us at{' '}
                <a href='mailto:admin@juraganakun.com'>admin@juraganakun.com</a>{' '}
                or call us at <a href='tel:+6283818213645'>+6283818213645</a>.
            </p>

            <p>Thank you for your trust in JURAGANAKUN!</p>
        </>
    );
};

export default PrivacyPolicy;
