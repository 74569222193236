import Spinner from './Spinner';

export default function LoadingTextBox(props) {
    return (
        <>
            {props.props.label != null ? (
                <small>{props.props.label}</small>
            ) : (
                <></>
            )}
            <div className='border border-indigo-700 shadow rounded-md p-1'>
                <div className='animate-pulse flex space-x-1'>
                    <Spinner />
                    <div className='flex-1 space-y-2 py-1'>
                        <div className='h-6 bg-slate-700 rounded'></div>
                    </div>
                </div>
            </div>
            {props.props.error != null ? (
                <small className='text-red-700'>{props.props.error}</small>
            ) : (
                <></>
            )}
        </>
    );
}
