import React from 'react';
import LoadingTextBox from '../LoadingTextBox';

const InputField = ({ 'data-loading': isLoading, ...props }) => {
    return (
        <>
            {isLoading ? (
                <LoadingTextBox props={props} />
            ) : (
                <>
                    {props.label != null ? (
                        <small className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
                            {props.label}
                        </small>
                    ) : (
                        <></>
                    )}
                    <input
                        className={`bg-white-50 border border-white-300 text-white-900 text-sm focus:ring-indigo-700 focus:border-indigo-700 block w-full p-1 ${props.className}`}
                        {...props}
                    ></input>
                    {props.error != null ? (
                        <small className='text-red-700'>{props.error}</small>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
};

export default InputField;
