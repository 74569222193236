import React from 'react';

export default function TOS() {
    return (
        <>
            <h1 className='text-center text-3xl font-bold tracking-tight sm:text-4xl'>
                Terms of Service
            </h1>
            <br />
            <p>
                <strong>Last Updated:</strong> October 1, 2023
            </p>
            <br />
            <p>Welcome to JURAGANAKUN!</p>
            <p>
                Please read these Terms of Service (referred to as the
                "Agreement") carefully before using our services.
            </p>
            <br />
            <h2 className='text-2xl font-bold'>1. Definitions</h2>
            <ul>
                <li>
                    <strong>We,</strong> <strong>us,</strong>{' '}
                    <strong>company</strong> refer to JURAGANAKUN.
                </li>
                <li>
                    <strong>You</strong> or <strong>user</strong> refers to the
                    individual or entity using our services.
                </li>
                <li>
                    <strong>Services</strong> refer to the social media
                    advertising services provided by JURAGANAKUN.
                </li>
            </ul>
            <br />
            <h2 className='text-2xl font-bold'>
                2. Acceptance of Terms and Conditions
            </h2>
            <p>
                By accessing or using our Services, you agree to be bound by
                these Terms and Conditions. If you do not agree to any of these
                terms, you may not use our Services.
            </p>
            <br />
            <h2 className='text-2xl font-bold'>3. Scope of Services</h2>
            <p>
                Our services encompass the planning, creation, execution, and
                analysis of social media advertising campaigns. We will
                collaborate with you to achieve mutually defined campaign
                objectives.
            </p>
            <br />
            <h2 className='text-2xl font-bold'>4. Payments</h2>
            <ul>
                <li>
                    Payments for our services will be governed by the agreement
                    signed or agreed upon in writing.
                </li>
                <li>
                    If there are additional charges incurred during a campaign,
                    you will be notified in advance.
                </li>
                <li>
                    All payments must be made in the currency specified in the
                    agreement.
                </li>
            </ul>
            <br />
            <h2 className='text-2xl font-bold'>5. Limitation of Liability</h2>
            <ul>
                <li>
                    We are not responsible for the outcomes of social media
                    campaigns conducted on third-party platforms (such as
                    Facebook, Instagram, Twitter, etc.).
                </li>
                <li>
                    We strive to minimize technical errors or unavailability of
                    our Services, but we are not liable for unavoidable
                    technical disruptions.
                </li>
                <li>
                    We are not responsible for the content published in social
                    media campaigns, and you are responsible for complying with
                    the guidelines and policies of the social media platforms
                    used.
                </li>
                <li>
                    We are not responsible for the content published by you, all
                    content you advertise is your own responsibility.
                </li>
            </ul>
            <br />
            <h2 className='text-2xl font-bold'>
                6. Changes and Cancellation of Services
            </h2>
            <ul>
                <li>
                    We reserve the right to change or terminate our Services
                    without prior notice.
                </li>
                <li>
                    You may cancel our Services by providing written notice as
                    per the terms in the agreement.
                </li>
            </ul>
            <br />
            <h2 className='text-2xl font-bold'>7. Privacy and Data Security</h2>
            <p>
                We will maintain the confidentiality of your data and will only
                use the information you provide for agreed-upon purposes. We
                will take necessary steps to secure your data.
            </p>
            <br />
            <h2 className='text-2xl font-bold'>8. Dispute Resolution</h2>
            <p>
                Any disputes arising in connection with this Agreement will be
                resolved through negotiation between the parties involved.
            </p>
            <br />
            <h2 className='text-2xl font-bold'>
                9. Changes to Terms and Conditions
            </h2>
            <p>
                We reserve the right to amend these Terms and Conditions and
                will notify you of such changes. The changes will take effect
                immediately upon notification.
            </p>
            <br />
            <h2 className='text-2xl font-bold'>10. Contact</h2>
            <p>
                If you have questions or need to contact us regarding these
                Terms and Conditions, please reach us at{' '}
                <a href='mailto:admin@juraganakun.com'>admin@juraganakun.com</a>{' '}
                or call us at <a href='tel:+6283818213645'>+6283818213645</a>.
            </p>
            <br />
            <p>
                By using our Services, you acknowledge that you have read,
                understood, and agreed to these Terms and Conditions. Thank you
                for your trust in JURAGANAKUN!
            </p>
        </>
    );
}
