import './../../App.css';
import React, { useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { NavLink, useNavigate } from 'react-router-dom';
import { showError, showSuccess } from '../../Components/Message';
import AuthService from '../../services/AuthService';
import InputField from '../../Components/Forms/InputField';
import Button from '../../Components/Forms/Button';

export default function Register() {
    const navigate = useNavigate();
    const defaultRegisterValue = {
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        password: '',
        phone: '',
    };

    const [registerForm, setRegisterForm] = useState(defaultRegisterValue);
    const [error, setError] = useState(defaultRegisterValue);
    const [registerLoading, setRegisterLoading] = useState(false);

    const registerHandler = async (e) => {
        e.preventDefault();
        setRegisterLoading(true);

        //request api
        try {
            const { data } = await AuthService.register(registerForm);
            if (data.success) {
                showSuccess(data.message);
                navigate('/login');
            } else {
                let response = data.response.data;
                showError(response.message);
            }
        } catch ({ response }) {
            try {
                if (response.data.error) {
                    setError(response.data.error);
                } else if (response.data.message) {
                    showError(response.data.message);
                } else {
                    showError(response);
                }
            } catch (error) {
                console.error(error);
                showError('Error sistem, hubungi admin WA : +6283818213645');
            }
        }
        setRegisterLoading(false);

        // dispatch(userRegister(registerForm));
    };

    //Component Did Update (Setelah Login)
    // useEffect(() => {
    //     if (getUserRegisterResult) {
    //         showSuccess(getUserRegisterResult.msg);
    //         dispatch(clearRegister());
    //         //Set data to local storage
    //         navigate('/login');
    //     } else if (getUserRegisterError) {
    //         showError(getUserRegisterError);
    //     }
    // }, [getUserRegisterResult, getUserRegisterError, navigate, dispatch]);

    return (
        <>
            <div className='flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8'>
                <div className='w-full max-w-md space-y-8'>
                    <div>
                        <img
                            className='mx-auto h-24 w-auto'
                            src='/logo/Logo Non BG Purple.png'
                            alt='Your Company'
                        />
                        <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
                            Register / Daftar User
                        </h2>
                        <p className='mt-2 text-center text-sm text-gray-600'>
                            <NavLink
                                to='/login'
                                className='font-medium text-indigo-600 hover:text-indigo-500'
                            >
                                Login
                            </NavLink>{' '}
                            Or{' '}
                            <NavLink
                                to='/'
                                className='font-medium text-indigo-600 hover:text-indigo-500'
                            >
                                Buy without login
                            </NavLink>
                        </p>
                    </div>
                    <form className='mt-8 space-y-6' onSubmit={registerHandler}>
                        <input
                            type='hidden'
                            name='remember'
                            defaultValue='true'
                        />
                        <div className='-space-y-px rounded-md shadow-sm'>
                            <div className='w-full'>
                                <InputField
                                    id='firstname'
                                    name='firstname'
                                    type='text'
                                    label='First Name'
                                    error={error.firstname}
                                    className='relative pl-2 block w-full rounded-lg border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    placeholder='First Name'
                                    onChange={(e) =>
                                        setRegisterForm({
                                            ...registerForm,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className='w-full pt-2'>
                                <InputField
                                    id='lastname'
                                    name='lastname'
                                    type='text'
                                    label='Last Name'
                                    error={error.lastname}
                                    className='relative pl-2 block w-full rounded-lg border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    placeholder='Last Name'
                                    onChange={(e) =>
                                        setRegisterForm({
                                            ...registerForm,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className='w-full pt-2'>
                                <InputField
                                    id='phone'
                                    name='phone'
                                    type='text'
                                    label='Phone Number'
                                    className='relative pl-2 block w-full rounded-lg border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    placeholder='Phone Number'
                                    error={error.phone}
                                    onChange={(e) =>
                                        setRegisterForm({
                                            ...registerForm,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className='w-full pt-2'>
                                <InputField
                                    id='username'
                                    name='username'
                                    type='text'
                                    label='Username'
                                    required
                                    className='relative pl-2 block w-full rounded-lg border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    placeholder='Username'
                                    error={error.username}
                                    onChange={(e) =>
                                        setRegisterForm({
                                            ...registerForm,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className='w-full pt-2'>
                                <InputField
                                    id='email'
                                    name='email'
                                    type='email'
                                    label='Email address'
                                    error={error.email}
                                    required
                                    className='relative pl-2 block w-full rounded-lg border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    placeholder='Email address'
                                    onChange={(e) =>
                                        setRegisterForm({
                                            ...registerForm,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className='w-full pt-2'>
                                <InputField
                                    id='password'
                                    name='password'
                                    type='password'
                                    label='Password'
                                    required
                                    className='relative pl-2 block w-full rounded-lg border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    placeholder='Password'
                                    error={error.password}
                                    onChange={(e) =>
                                        setRegisterForm({
                                            ...registerForm,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>

                        <div>
                            <Button
                                type='submit'
                                data-loading={registerLoading}
                                icon={
                                    <LockClosedIcon
                                        className='h-5 w-5 text-white-500 group-hover:text-white-400'
                                        aria-hidden='true'
                                    />
                                }
                                label={'Sign In'}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
