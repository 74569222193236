export default function Header() {
    return (
        <div className='relative'>
            <div className='mx-auto max-w-2xl'>
                {/* <div className='hidden sm:mb-8 sm:flex sm:justify-center'>
                            <div className='relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20'>
                                Announcing our next round of funding.{' '}
                                <a
                                    href='#'
                                    className='font-semibold text-indigo-600'
                                >
                                    <span
                                        className='absolute inset-0'
                                        aria-hidden='true'
                                    />
                                    Read more{' '}
                                    <span aria-hidden='true'>&rarr;</span>
                                </a>
                            </div>
                        </div> */}
                <div className='text-center'>
                    <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl'>
                        JURAGANAKUN.COM
                    </h1>
                    <p className='mt-6 text-lg leading-8 text-gray-600'>
                        Melayani jasa periklanan, jual beli akun sosial media,
                        jasa kelola sosial media dan jasa Digital Marketing
                        lainnya.
                    </p>
                    {/* <div className='mt-10 flex items-center justify-center gap-x-6'>
                                <a
                                    href='#'
                                    className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                >
                                    Get started
                                </a>
                                <a
                                    href='#'
                                    className='text-sm font-semibold leading-6 text-gray-900'
                                >
                                    Learn more <span aria-hidden='true'>→</span>
                                </a>
                            </div> */}
                    <div class='grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible'>
                        <div
                            role='alert'
                            class='relative block w-full text-base font-regular px-4 py-4 rounded-lg bg-red-500 text-white flex'
                        >
                            <div class=' mr-12'>
                                <p class='font-bold'>
                                    Whatsapp kami sedang bermasalah, untuk
                                    sementara dialihkan ke nomor :
                                    {process.env.REACT_APP_PHONE_CODE +
                                        process.env.REACT_APP_PHONE_NUMBER}
                                    <a
                                        target='_blank'
                                        class='inline-flex items-center justify-center text-sm font-bold text-green-200 transition-all ml-4 duration-200 rounded-md hover:text-green-700'
                                        role='button'
                                        href={`https://wa.me/${
                                            process.env.REACT_APP_PHONE_CODE +
                                            process.env.REACT_APP_PHONE_NUMBER
                                        }`}
                                    >
                                        CHAT SEKARANG{' '}
                                        <svg
                                            class='w-4 h-4 ml-1'
                                            viewBox='0 0 24 24'
                                            stroke='currentColor'
                                            stroke-width='2'
                                            fill='none'
                                            stroke-linecap='round'
                                            stroke-linejoin='round'
                                        >
                                            <path d='M5 12h14'></path>
                                            <path d='M12 5l7 7-7 7'></path>
                                        </svg>
                                    </a>
                                </p>
                            </div>
                            <button
                                class='relative align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-8 max-w-[32px] h-8 max-h-[32px] rounded-lg text-xs text-white hover:bg-white/10 active:bg-white/30 !absolute top-3 right-3'
                                type='button'
                            >
                                <span class='absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        stroke='currentColor'
                                        class='h-6 w-6'
                                        stroke-width='2'
                                    >
                                        <path
                                            stroke-linecap='round'
                                            stroke-linejoin='round'
                                            d='M6 18L18 6M6 6l12 12'
                                        ></path>
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className='absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]'
                aria-hidden='true'
            >
                <div
                    className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]'
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
        </div>
    );
}
