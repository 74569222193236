import http from '../http-common';

const login = (data) => {
    return http.post('/auth/login', data);
};

const register = (data) => {
    return http.post('/auth/register', data);
};

const AuthService = {
    login,
    register,
};

export default AuthService;
