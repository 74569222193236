import axios from 'axios';
import { ipApi } from '../setting';
import { getToken } from '../Helpers';
import AuthService from '../services/AuthService';

export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';
export const CHECK_PASSWORD = 'CHECK_PASSWORD';

export const loginRequest = (loginData) => {
    return async (dispatch) => {
        //default dispatch
        dispatch({
            type: LOGIN,
            payload: {
                loading: true,
                status: 'process',
                data: false,
                errorMessage: false,
            },
        });

        try {
            const { data } = await AuthService.login(loginData);

            const auth = {
                isAuth: true,
                data: data,
                user: data.data,
                token: data.token,
            };

            //store token to
            await localStorage.setItem('auth', JSON.stringify(auth));

            //dispatch success
            dispatch({
                type: LOGIN,
                payload: {
                    loading: false,
                    status: 'success',
                    data: auth,
                    errorMessage: false,
                },
            });

            return Promise.resolve(data);
        } catch ({ response }) {
            //gagal
            dispatch({
                type: LOGIN,
                payload: {
                    loading: false,
                    status: 'error',
                    data: false,
                    errorMessage: response.data.msg,
                },
            });
            return Promise.reject(response);
        }
    };
};

export const doCheckPassword = (data) => {
    return (dispatch) => {
        //default dispatch
        dispatch({
            type: CHECK_PASSWORD,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            },
        });

        //get from API
        axios({
            method: 'POST',
            url: ipApi + '/auth/checkpassword',
            timeout: '120000',
            data: { password: data },
            headers: {
                Authorization: getToken(),
            },
        })
            .then((response) => {
                dispatch({
                    type: CHECK_PASSWORD,
                    payload: {
                        loading: false,
                        data: response.data,
                        errorMessage: false,
                    },
                });
            })
            .catch((error) => {
                //gagal
                dispatch({
                    type: CHECK_PASSWORD,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    },
                });
            });
    };
};

export const userRegister = (data) => {
    return (dispatch) => {
        //default dispatch
        dispatch({
            type: REGISTER,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            },
        });

        //get from API
        axios({
            method: 'POST',
            url: ipApi + '/auth/register',
            timeout: '120000',
            data: data,
        })
            .then((response) => {
                //2. berhasil call API
                dispatch({
                    type: REGISTER,
                    payload: {
                        loading: false,
                        data: response.data,
                        errorMessage: false,
                    },
                });
            })
            .catch((error) => {
                //gagal
                dispatch({
                    type: REGISTER,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    },
                });
            });
    };
};

export const doLogOut = () => {
    return (dispatch) => {
        localStorage.removeItem('auth');

        dispatch({
            type: LOGIN,
            payload: {
                loading: false,
                data: false,
                errorMessage: false,
            },
        });
    };
};

export const clearRegister = (type) => {
    return (dispatch) => {
        dispatch({
            type: REGISTER,
            payload: {
                loading: false,
                data: false,
                errorMessage: false,
            },
        });
    };
};
